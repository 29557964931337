import React, {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
} from "react";
import {
  IntlProvider,
  createIntl,
  createIntlCache,
  IntlShape,
} from "react-intl";
import messages_en from "../../intl/en.json";
import messages_ko from "../../intl/ko.json";

interface LanguageContextType {
  locale: string;
  changeLocale: (newLocale: string) => void;
  t: (id: string, values?: Record<string, string>) => ReactNode;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

interface LanguageProviderProps {
  children: ReactNode;
}

const messages: { [locale: string]: Record<string, string> } = {
  en: messages_en,
  ko: messages_ko,
};

const cache = createIntlCache();

const createIntlInstance = (
  locale: string,
  messages: Record<string, string>
): IntlShape => {
  return createIntl(
    {
      locale,
      messages,
      defaultLocale: "en",
    },
    cache
  );
};

const getBrowserLocale = (): string => {
  const supportedLocales = Object.keys(messages);

  const browserLocale =
    typeof window !== "undefined"
      ? window.navigator.language.split("-")[0]
      : "ko";

  if (supportedLocales.includes(browserLocale)) {
    return browserLocale;
  }

  return "ko";
};

const getInitialLocale = (): string => {
  const storedLocale =
    typeof window !== "undefined" && localStorage.getItem("locale");
  if (storedLocale && messages[storedLocale]) {
    return storedLocale;
  }
  return getBrowserLocale();
};

export const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
  const [locale, setLocale] = useState<string>(() => getInitialLocale());
  const [intl, setIntl] = useState<IntlShape>(() =>
    createIntlInstance(locale, messages[locale])
  );

  const changeLocale = (newLocale: string) => {
    setLocale(newLocale);
    setIntl(createIntlInstance(newLocale, messages[newLocale]));
    localStorage.setItem("locale", newLocale);
  };

  console.log(locale);
  console.log(messages[locale]);
  console.log(intl);

  const t = (id: string, values?: Record<string, any>): ReactNode => {
    return intl.formatMessage({ id }, values);
  };

  return (
    <LanguageContext.Provider value={{ locale, changeLocale, t }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
